<template>
  <div class="news-wrapper">
    <div class="main-content">
      <div class="title-tile">
        <h4 class="date">{{ rewritedate }}</h4>
        <router-link :to="newsLink(this.data)" class="title">{{ this.data.attributes.title }}</router-link>
        <h4 v-if="this.data.attributes.field_is_opportunity_news" :class="['date deadline',isDeadlineExpired]"><span> 
          <span v-if="isDeadlineExpired">NOW CLOSED:</span>
          <span v-else>DEADLINE:</span>
           </span> {{ deadline }}</h4>
      </div>
      <div class="content-tile">
        <div :class="{'description': true, 'show': this.showmore}"
          v-if="this.data.attributes && this.data.attributes.field_news_content"
          v-html="this.data.attributes.field_news_content.summary"></div>
        <div>
          <router-link :to="newsLink(this.data)" class="cta-link">
            <span>Learn More</span>
          </router-link>
        </div>
      </div>
    </div>
    <router-link :to="newsLink(this.data)" class="image-tile" :style="newsImage" v-if="this.data.field_news_sector">
      
      <div class="tag-container"  v-if="this.data.attributes.field_is_opportunity_news != true">
        <span class="image-tag" v-for="(s, index) in this.data.field_news_sector"
          :key="'sector'+index">{{ s.attributes.name }}</span>
      </div>

      <div class="tag-container">
        <span class="image-tag" v-for="(s, index) in this.data.field_target"
          :key="'sector'+index">{{ s.attributes.name }}</span>
      </div>

    </router-link>
  </div>
</template>

<script>
  import {
    convertMonthShort,
    detailsLink
  } from '../libs/utils'
  export default {
    name: 'single-news',
    data: () => {
      return {
        showmore: false
      }
    },
    props: {
      data: Object
    },
    methods: {
      newsLink(n) {
        return detailsLink('news', n)
      }
    },
    computed: {
      newsImage() {
        if (this.data.field_news_image)
          return 'background-image: url("' + process.env.VUE_APP_ENDPOINT + this.data.field_news_image.attributes.uri
            .url + '");';
        else return "background-image: url('collection-newspapers.jpg')"
      },
      rewritedate() {
        var d = new Date(Date.parse(this.data.attributes.field_news_data))
        return d.getDate() + ' ' + convertMonthShort(d.getMonth()) + ' ' + d.getFullYear()
      },
      deadline() {
        if(this.data.attributes.field_is_opportunity_news && this.data.attributes.field_opportunity_deadline) {
          var d = new Date(Date.parse(this.data.attributes.field_opportunity_deadline))
          return d.getDate() + ' ' + convertMonthShort(d.getMonth()) + ' ' + d.getFullYear()
        } else
          return ''
      },
      isDeadlineExpired(){
        let today = new Date();
        let deadlineDate = new Date(this.data.attributes.field_opportunity_deadline);

        deadlineDate = deadlineDate.setDate(deadlineDate.getDate() + 1)


        if( today>= deadlineDate ){
          return 'expired'
        } else {
          return ""
        }
        
      }

    },
    mounted() {
      //console.log("asd", this.data);
    }
  }
</script>

<style lang="scss" scoped>
  @import "../styles/colors.scss";

  .deadline {
    line-height: 1.5em;
    padding: .3em .7em;
    margin: 1em 0 2.5px 0;
    background: $mainColor;
    color: $secondaryColor;
    //font-weight: 700;
    font-size: .9em;
    //text-transform: uppercase;
    width: fit-content;
    span {
      font-weight: 700;
    }
  }

  .deadline.expired {
    background: #ffffff;
  }
  .news-wrapper .title-tile .date.deadline.expired span,
  .news-wrapper .title-tile .date.deadline.expired {
    color:#959595;
    padding-left:0;
  }

  .news-wrapper {
    display: flex;
    display: -webkit-flex;
    flex-direction: column-reverse;

    @media (min-width: 992px) {
      flex-direction: row;
    }

    padding: 1.5em 0rem;
    min-height: 250px;
    border-bottom: 2px #eee solid;

    .main-content {
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      width: 100%;

      @media (min-width: 992px) {
        flex-direction: row;
        width: 66%;
      }

      div {
        padding-right: 2rem;
      }

      .description {
        font-size: .92em;
        line-height: 1.8em;

        strong {
          font-weight: 700;
          color: $secondaryColor;
        }
      }
    }

    .title-tile {
      width: 100%;

      @media (min-width: 992px) {
        width: 50%;
      }

      .date {
        font-size: 0.9375rem;
        color: #404259;
      }

      .title {
        display: block;
        text-decoration: none;
        color: $secondaryColor;
        font-size: 1.625rem;
        font-weight: 700;
        margin-top: 16px;

        &:hover {
          cursor: pointer;
          transition: 0.2s ease;
          color: $mainColor;
        }

        @media (max-width: 992px) {
          padding: 0;
        }
      }
    }

    .content-tile {
      width: 100%;

      .description {

        //margin-top: 40px;
        p {
          font-size: 1rem;
        }
      }

      @media (min-width: 992px) {
        width: 50%;
      }

      .description:not(.show) {
        &::v-deep p:not(:first-child) {
          display: none;
        }
      }

      .cta-link {
        text-decoration: none;
      }

      .readmore {
        position: relative;
        color: $secondaryColor;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
        margin: 0;
        margin-top: 2em;
        margin-bottom: 1em;
        display: block;
        width: fit-content;

        &:hover {
          color: lighten($secondaryColor, 20%);
        }

        &:before {
          content: '';
          background: $mainColor;
          width: 8px;
          height: 2em;
          top: -.25em;
          left: 0;
          position: absolute;
          display: block;
          z-index: -1;
        }

        &:after {
          content: '';
          top: .25em;
          right: -1.5em;
          background-image: url('../assets/dropdown-icon.svg');
          width: 1em;
          height: 1em;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          display: inline-block;
        }

        &.close::after {
          transform: rotate(180deg);
        }

      }
    }

    .image-tile {
      position: relative;
      display: block;
      width: 100%;
      margin: 0;
      min-height: 50px;
      max-height: 250px;

      &::before {
        content: "";
        opacity: 0.5;
        background-image: url("../assets/pattern-overlay-person.png");
        width: 100%;
        height: 100%;
        z-index: 0;
        top: 0;
        right: 0;
        display: block;
        position: absolute;
      }

      @media (min-width: 992px) {
        width: 33%;
        height: auto;
      }

      background-image: url(../assets/mobile-logo.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      .tag-container {
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        position: relative;
        justify-content: flex-start;
        width: 90%;
        margin: 0 auto;
      }

      .image-tag {
        position: relative;
        margin-right: 10px;
        font-size: .75em;
        padding: .75em 1.5em;
        border-bottom-right-radius: 1em;
        border-bottom-left-radius: 1em;
        background: $secondaryColor;
        color: $mainColor;
        text-transform: uppercase;
        font-weight: 700;
        top: 0;
        left: 0;
      }


      &::after {
        position: absolute;
        display: block;
        width: 22px;
        height: 22px;
        content: "";
        top: 10px;
        right: 20px;
        border-style: solid;
        border-color: $mainColor;
        border-width: 7px 7px 0px 0px;
      }

    }
  }

  @media (max-width:1200px) {
    .news-wrapper {
      .main-content {
        flex-direction: column;

        .title-tile,
        .content-tile {
          width: 100%;
        }
      }

      .image-tile {
        width: 34%;
      }
    }
  }

  @media (max-width:1200px) {
    .news-wrapper {
      .main-content {
        div {
          padding-right: 0;
        }
      }

      .image-tile {
        width: 100%;
        margin-bottom: 20px;
      }

      .content-tile {
        padding-right: 0;

        .description {
          margin-top: 10px;
          padding-right: 0;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .news-wrapper .image-tile {
      min-height: 210px;
    }
  }

</style>